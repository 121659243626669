import React, { Component } from 'react'
import {Route} from 'react-router-dom'
import {StorworkInit} from 'storwork-stores'
import {observer} from 'mobx-react'
import getStore from './Store'
import _ from 'lodash'

const Application = observer(class _Application extends Component {

  balanceRows = (rows, length) => rows.filter(row => row.filter(cell => cell !== '').length > 0).concat([this.props.store.emptyRow(length)])

  modifyXY = (x, y) => e => {
    e.persist()
    this.props.store.data.rows[x][y] = e.target.value
    this.props.store.data.rows = this.balanceRows(this.props.store.data.rows, this.props.store.data.columns.length)
  }

  modifyColumn = (x) => e => {
    e.persist()
    this.props.store.data.columns[x].name = e.target.value
    if (x === this.props.store.data.columns.length - 1) {
      this.props.store.data.columns.push(this.props.store.emptyColumn())
      this.props.store.data.rows.map(row => row.push(''))
    }
  }

  render() {
    const {columns, rows, name} = this.props.store.data
    return <div>
      <div>
        <input style={{padding: 20, color: 'black', fontWeight: 'bold'}} type='text' value={name}
          onBlur = {() => this.props.store.sync()}
          onChange={e => { this.props.store.data.name = e.target.value}}
          onFocus = {() => this.props.store.lock = 'name'}
        />
      </div>
      <div style={{padding: '0 20px'}}>
        <table>
          <thead><tr>
            <th></th>
            {columns.map((el, x) => <th key={x} style={{width: el.width}}>
              <input type='text' value={el.name}
                onBlur = {() => this.props.store.sync()}
                onChange={this.modifyColumn(x)}
                onFocus = {() => this.props.store.lock = 'columns.'+x}
              />
            </th>)}
          </tr></thead>
          <tbody>
            {rows.map((row, x) => <tr key={x}>
              <th>{x+1}</th>
              {row.map((cell, y) => <td key={y}>
                <input type='text' value={cell}
                  onBlur = {() => this.props.store.sync()}
                  onChange={this.modifyXY(x, y)}
                  onFocus = {() => this.props.store.lock = 'rows.'+x+'.'+y}
                />
              </td>)}
            </tr>)}
          </tbody>
        </table>
      </div>
    </div>
  }
})

const ApplicationWithStore = props => <Application store={getStore(props.match.params.id)} />

class App extends Component {
  render() {
    return <StorworkInit loggedIn={
      <Route exact path='/:id' component={ApplicationWithStore} />
    } />
  }
}

export default App;
