import client from 'socket.io-client'
import { decorate, observable } from 'mobx'
import { JWT } from 'storwork-stores'
import { debounce } from 'throttle-debounce'
import _ from 'lodash'

let store = null
export default (id) => {
  if (!store) store = new Store(id)
  return store
}

const Store = decorate(class _Store {
  data = null
  socket = null
  query = null
  loaded = false
  responce = null
  lock = null
  setQuery = query => {
    this.query = query
    this.socket.emit('newQuery', this.query)
  }
  //sync = () => {this.lock = null; this._sync()}
  sync = debounce(2000, () => {
    console.log('sync')
    this.responce.search[0].json = JSON.stringify(this.data)
    this.socket.emit('sync', this.responce)
  });

  getData = data => {
    console.log(data);
    if (data.search[0] && data.search[0].type === "spreadsheet"){
      this.responce = data
      if (this.lock) {
        let _data = JSON.parse(data.search[0].json)
        _.set(_data, this.lock, _.get(this.data, this.lock))
        this.data = _data
      } else {
        let dataModified = JSON.parse(data.search[0].json)
        dataModified.columns = dataModified.columns || this.emptyColumns()
        dataModified.rows = dataModified.rows || this.emptyRows()
        this.data = dataModified
      }
      this.loaded = true
    }
  }

  initSocket = (query) => {
    const socket = client('https://mega.storwork.pl', { //188.116.2.82:3001 //http://localhost:3001
      query: {
        authorization: "Bearer " + JWT.get()
      }
    })
    socket.on('connect', () => {
      console.log('Connected')
      this.socket = socket
      this.socket.on('disconnect', function(){
        console.log('Disconnected')
      })
      this.socket.on('newDataLoaded', this.getData)
      if (query) {
        console.log('Initial query set')
        this.setQuery(query)
      }
    })
  }
  emptyColumn = () => ({
    name: '',
    size: 50
  })
  emptyColumns = (length = 1) => Array.from({length}, this.emptyColumn)
  emptyRow = (length = 1) => Array.from({length}, () => '')
  emptyRows = (length = 1) => Array.from({length}, this.emptyRow)

  constructor(id) {
    console.log(id)
    const query = `{
      search (id:${id}, type:"generalobject") {
        ... on GeneralObject {
          id
          json
          type
          indexType
        }
      }
    }`
    this.initSocket(query)
    this.data = {
      name: "",
      columns: this.emptyColumns(),
      rows: this.emptyRows()
    }
  }
}, {
  data: observable,
  query: observable,
  loaded: observable
})
